import axios from 'axios';
import { MapLike } from 'typescript';
import { getToken } from './utils';
import {
  ActivationFlowData,
  SubmitTosResult,
  SubmitTOSPayload,
  AfActionsPayload,
  SubmitCouponParams,
  SubmitCouponResult,
  SubmitPaymentParams,
  RejectMembershipParams,
  SetupIntentResult,
  SubmitPaymentResult,
  UpcomingInvoiceParams,
  UpcomingInvoiceResponse,
  SubscriptionDetails,
  PaymentMethod,
  PartnerConfigRule,
} from './types';
import { afConfig } from './config';

export class OvAPI {
  private baseUrl = afConfig.api + '/v2/a';
  private baseActivationFlowUrl =
    afConfig.activationFlowApi +
    '/v2' +
    (afConfig.stage === 'local' ? '/a' : '');

  private formHeaders = async () => {
    const token = getToken();
    if (!token) {
      throw new Error('Can`t receive token');
    }

    const headers: MapLike<string | number> = {
      'content-type': 'application/json',
      'ov-app-id': 'activation-flow',
      Authorization: token,
    };

    return headers;
  };

  private baseGet = async <T>(
    path: string,
    params?: MapLike<string | number | boolean>,
  ) => {
    return await axios.get<T>(`${this.baseUrl}/${path}`, {
      headers: await this.formHeaders(),
      params,
    });
  };

  private baseActivationFlowGet = async <T>(
    path: string,
    params?: MapLike<string | number | boolean>,
  ) => {
    return await axios.get<T>(`${this.baseActivationFlowUrl}/${path}`, {
      headers: await this.formHeaders(),
      params,
    });
  };

  private baseActivationFlowPost = async <T>(
    path: string,
    payload: unknown,
    params?: MapLike<string>,
  ) => {
    const fullPath = `${this.baseActivationFlowUrl}/${path}`;

    const response = await axios.post<T>(fullPath, payload, {
      headers: await this.formHeaders(),
      params,
    });

    return response;
  };

  // just to check if backend is up and running
  // will remove once we have actual endpoints
  public healthCheck = () => {
    return this.baseGet('health-check');
  };

  public addressGetByZdOrgId = (zdOrgId: number) => {
    return this.baseGet<MapLike<unknown>[]>('address', {
      'zd-org-id': zdOrgId,
    });
  };

  public getActivationFlowData = async () =>
    this.baseActivationFlowGet<{ data: ActivationFlowData }>(
      'activation-flow-data',
    );

  public submitTOS = async (payload: SubmitTOSPayload) =>
    this.baseActivationFlowPost<SubmitTosResult>(
      'activation-flow-submit-tos',
      payload,
    );

  public submitActivationFlowAction = async (payload: AfActionsPayload) =>
    this.baseActivationFlowPost('activation-flow-actions', payload);

  public submitCoupon = async (params: SubmitCouponParams) =>
    this.baseActivationFlowGet<SubmitCouponResult>(
      'stripe-coupon',
      params,
    );

  public getUpcomingInvoice = async (params: UpcomingInvoiceParams) =>
    this.baseActivationFlowGet<{ data: UpcomingInvoiceResponse }>(
      'activation-flow-upcoming-invoice',
      params,
    );

  public getStripeSubscriptionDetails = async ({
    addressId,
  }: {
    addressId: string;
  }) =>
    this.baseActivationFlowGet<{ data: SubscriptionDetails }>(
      'subscription-data',
      {
        'address-id': addressId,
      },
    );

  public getPaymentMethods = async (params: {
    'customer-id': SubscriptionDetails['customerId'];
  }) =>
    this.baseActivationFlowGet<{ data: PaymentMethod[] }>(
      'payment-method',
      params,
    );

  public submitPayment = async (
    params: SubmitPaymentParams | RejectMembershipParams,
  ) =>
    this.baseActivationFlowPost<SubmitPaymentResult>(
      'activation-flow-signup',
      params,
    );

  public setupIntent = async (payload: { customerId?: string }) =>
    this.baseActivationFlowPost<SetupIntentResult>(
      'activation-flow-setup-intent',
      {
        ...payload,
      },
    );

  public requestAfCode = async (payload: { email: string }) =>
    this.baseActivationFlowPost('activation-flow-auth-code', payload);

  public sendAfCode = async (payload: { token: string; code: string }) =>
    this.baseActivationFlowPost<{ data: { isCodeValid: boolean } }>(
      'activation-flow-validate-auth-code',
      payload,
    );

  public getPartnerConfig = async () =>
    this.baseActivationFlowGet<{ data: PartnerConfigRule[] }>(
      'partner-config',
    );

  public axios = axios;
}

export const API = new OvAPI();
